import { Routes } from '@angular/router';
import { protectedGuard, publicGuard } from 'ngx-auth';
import { TenantDetailsResolver } from '@buspro/web/core';
import { AdminLoginComponent } from '@buspro/web/admin/auth';
import {
  AdminWorkspaceLayoutComponent,
  PageNotFoundComponent,
} from '@buspro/web/admin/shared';
import { TenantsComponent } from '@buspro/web/admin/tenants/directory';
import { TenantsLayoutComponent } from '@buspro/web/admin/tenants/shared';
import { DashboardComponent } from '@buspro/web/admin/dashboard';

export const routes: Routes = [
  {
    path: 'passport/login',
    canActivate: [publicGuard],
    canActivateChild: [publicGuard],
    component: AdminLoginComponent,
    data: { title: 'Login', preload: true },
  },

  {
    path: 'tenants',
    canActivate: [protectedGuard],
    canActivateChild: [protectedGuard],
    component: AdminWorkspaceLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'directory',
      },
      {
        path: 'directory',
        component: TenantsComponent,
        data: { title: 'Tenants' },
      },
      {
        path: 'context/:tid',
        component: TenantsLayoutComponent,
        resolve: { tenant: TenantDetailsResolver },
        children: [
          {
            path: 'overview',
            loadComponent: () =>
              import('@buspro/web/admin/tenants/dashboard').then(
                (c) => c.TenantOverviewComponent
              ),
            data: { title: 'Overview' },
          },
          {
            path: 'dashboard',
            loadComponent: () =>
              import('@buspro/web/tenants/dashboard').then(
                (c) => c.TenantDashboardComponent
              ),
            data: { title: 'Dashboard', preload: true },
          },
          {
            path: 'monitor',
            loadChildren: () =>
              import('@buspro/web/tenants/monitor').then(
                (m) => m.MonitorModule
              ),
            data: { title: 'Monitor' },
          },
          {
            path: 'schedules',
            loadChildren: () =>
              import('@buspro/web/tenants/operations').then(
                (m) => m.OperationsModule
              ),
            data: { title: 'Schedules' },
          },
          {
            path: 'bookings',
            loadChildren: () =>
              import('@buspro/web/tenants/bookings').then(
                (m) => m.BookingModule
              ),
            data: { title: 'Bookings' },
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('@buspro/web/tenants/reports').then(
                (m) => m.ReportsModule
              ),
            data: { title: 'Reports' },
          },
          {
            path: 'sims',
            loadChildren: () =>
              import('@buspro/web/tenants/buses').then((m) => m.SimModule),
            data: { title: 'SIMS' },
          },

          {
            path: 'devices',
            loadChildren: () =>
              import('@buspro/web/tenants/buses').then((m) => m.DeviceModule),
          },
          {
            path: 'drivers',
            loadChildren: () =>
              import('@buspro/web/tenants/drivers').then(
                (m) => m.DriversModule
              ),
          },
          {
            path: 'conductors',
            loadChildren: () =>
              import('@buspro/web/tenants/conductors').then(
                (m) => m.ConductorsModule
              ),
          },
          {
            path: 'routes',
            loadChildren: () =>
              import('@buspro/web/tenants/routes').then((m) => m.RoutesModule),
          },
          {
            path: 'bus-stops',
            loadChildren: () =>
              import('@buspro/web/tenants/places').then(
                (m) => m.BusStopsModule
              ),
          },

          {
            path: 'terminals',
            loadChildren: () =>
              import('@buspro/web/tenants/places').then(
                (m) => m.TerminalsModule
              ),
          },
      
          {
            path: 'assets',
            loadChildren: () =>
              import('@buspro/web/tenants/buses').then((m) => m.BusesModule),
            data: { title: 'Buses' },
          },
          {
            path: 'audits',
            loadChildren: () =>
              import('@buspro/web/admin/tenants/audits').then(
                (m) => m.AuditsModule
              ),
            data: { title: 'Audits' },
          },

          {
            path: 'parcels',
            loadChildren: () =>
              import('@buspro/web/tenants/parcels').then(
                (m) => m.ParcelsModule
              ),
            data: { title: 'Parcels' },
          },
          {
            path: 'operations',
            loadChildren: () =>
              import('@buspro/web/tenants/operations').then(
                (m) => m.OperationsModule
              ),
          },
          {
            path: 'monitor',
            loadChildren: () =>
              import('@buspro/web/tenants/monitor').then(
                (m) => m.MonitorModule
              ),
          },
          {
            path: 'users',
            loadChildren: () =>
              import('@buspro/web/tenants/users').then(
                (m) => m.UserManagementModule
              ),
            data: { title: 'Users' },
          },
        ],
      },
      {
        path: '**',
        component: PageNotFoundComponent,
        data: { title: '404' },
      },
    ],
  },

  {
    path: '',
    canActivate: [protectedGuard],
    canActivateChild: [protectedGuard],
    component: AdminWorkspaceLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        data: { title: 'Dashboard', preload: true },
      },
      {
        path: 'standard-places',
        loadChildren: () =>
          import('@buspro/web/admin/standard-places').then(
            (m) => m.StandardPlacesModule
          ),
      },
      { path: '**', component: PageNotFoundComponent },
    ],
  },

  { path: '**', component: PageNotFoundComponent },
];
