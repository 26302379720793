import { WebAppEnvironment } from '@buspro/web/core';

export const environment: WebAppEnvironment = {
  env: 'PROD',
  production: true,
  api: 'https://apis.buspro.biz/api',
  server: 'https://apis.buspro.biz',
  s3: {
    accessKeyId: 'AKIA6QBENJSJQYNEIP6F',
    secretAccessKey: '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',
    region: 'eu-west-2',
    buspro_company_logos_bucket: 'buspro-company-logos',
  },
  publicWebsiteUrl: 'https://buspro.biz',
  googleMapsApiKey: 'AIzaSyBq9U9E6eIPmLDNcDwx4Nx8mf_jebRL1FU',
  mdvr: {
    url: 'https://mdvr.buspro.biz',
    account: 'admin',
    password: 'Hagvan@2022',
  },
  pusher: {
    appKey: 'd3b4f1f78a161ffb6a0d',
    cluster: 'mt1',
  },
};
