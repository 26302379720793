import { Component, inject, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { GlobalSettingsService, LocalAuthService } from '@buspro/web/core';
import { NgProgressHttp } from 'ngx-progressbar/http';
import { NgProgressRouter } from 'ngx-progressbar/router';
import { NgProgressbar } from 'ngx-progressbar';

@UntilDestroy()
@Component({
  selector: 'buspro-root',
  standalone: true,
  imports: [
    RouterOutlet,
    AngularQueryDevtools,
    NgProgressHttp,
    NgProgressRouter,
    NgProgressbar,
  ],
  template: `
    <main>
      <ng-progress
        direction="ltr+"
        min="20"
        max="1"
        speed="200"
        trickleSpeed="300"
        ease="linear"
        debounceTime="100"
        relative
        [flat]="false"
        [spinner]="false"
        [spinnerPosition]="'left'"
        ngProgressHttp
        ngProgressRouter
      />

      <router-outlet #o="outlet"></router-outlet>
      <angular-query-devtools [initialIsOpen]="false" />
    </main>
  `,
})
export class AppComponent implements OnInit {
  private authService = inject(LocalAuthService);

  private logger = inject(NGXLogger);
  authUser$ = this.authService.currentUser$;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          this.authService.setInterruptedUrl(this.router.url);
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .pipe(untilDestroyed(this))
      .subscribe((event) => {
        this.titleService.setTitle('BusPro Admin | ' + event['title']);
      });
  }
}
