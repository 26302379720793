import { Component, inject, signal } from '@angular/core';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { MatIconModule } from '@angular/material/icon';
import { NzEmptyComponent } from 'ng-zorro-antd/empty';
import { AvatarModule } from 'ngx-avatars';
import { injectQuery } from '@tanstack/angular-query-experimental';
import { MatButtonModule } from '@angular/material/button';
import { NgxLoadingModule } from 'ngx-loading';
import { NzSkeletonComponent } from 'ng-zorro-antd/skeleton';
import { NzResultComponent } from 'ng-zorro-antd/result';
import { MomentModule } from 'ngx-moment';
import { RouterLink } from '@angular/router';
import { EmptyComponent } from '@buspro/web/shared';
import { AppService, CacheKeys, TenantService } from '@buspro/web/core';
import { CompanyStatusComponent } from '@buspro/web/admin/shared';
import { RegisterTenantModalComponent } from '@buspro/web/admin/tenants/shared';
import { TenantInListDto } from '@buspro/shared/dtos';

@Component({
  selector: 'buspro-admin-widgets',
  standalone: true,
  imports: [
    MatIconModule,
    NzPageHeaderModule,
    NzEmptyComponent,
    MatButtonModule,
    AvatarModule,
    CompanyStatusComponent,
    NgxLoadingModule,
    RegisterTenantModalComponent,
    NzSkeletonComponent,
    NzResultComponent,
    EmptyComponent,
    MomentModule,
    RouterLink,
  ],
  template: `
    <section class="bg-white p-8 min-h-full">
      <nz-page-header [nzTitle]="title">
        <ng-template #title>
          <p class="text-3xl font-bold">Tenants</p>
        </ng-template>
        <nz-page-header-extra>
          <button (click)="showRegisterTenantModal()" class="btn btn-primary">
            <mat-icon>add_domain</mat-icon>
            Register New Business
          </button>
        </nz-page-header-extra>
      </nz-page-header>
      <!--      <nz-divider></nz-divider>-->

      @if (query.isError()) {
      <nz-result
        nzStatus="500"
        nzTitle="500"
        nzSubTitle="Sorry, there is an error on the server."
      >
        <div nz-result-extra>
          <div>
            If problem persist, kindly contact technical support for assistance.
          </div>
        </div>
      </nz-result>
      }

      <ngx-loading
        [show]="query.isLoading() && !query.isInitialLoading"
        [config]="{ backdropBackgroundColour: '#ccc' }"
      ></ngx-loading>

      @if (!query.isFetched && query.isInitialLoading() && query.isLoading()) {
      <nz-skeleton
        [nzActive]="true"
        [nzParagraph]="true"
        [nzRound]="true"
      ></nz-skeleton>
      <nz-skeleton
        [nzActive]="true"
        [nzParagraph]="true"
        [nzRound]="true"
      ></nz-skeleton>
      <nz-skeleton
        [nzActive]="true"
        [nzParagraph]="true"
        [nzRound]="true"
      ></nz-skeleton>
      <nz-skeleton
        [nzActive]="true"
        [nzParagraph]="true"
        [nzRound]="true"
      ></nz-skeleton>
      } @if (query.isFetched() && query.data() && query.data()?.length) {
      <table class="table">
        <thead>
          <tr>
            <th>Company Name</th>
            <th>Admin Info</th>
            <th>Status</th>
            <th>Managed Services</th>
          </tr>
        </thead>
        <tbody>
          @for (data of tenants; track data.id) {
          <tr>
            <td class="flex items-center space-x-2">
              <ngx-avatars size="64" [src]="data.logoUrl"></ngx-avatars>
              <div>
                <div class="font-bold ">{{ data.name }}</div>
                <div class="text-mute text-sm text-gray-500">
                  Joined {{ data.createdAt | amTimeAgo }}
                </div>
              </div>
            </td>
            <td>
              <div class="font-medium">
                @if (data.admin.firstName && data.admin.surname) {
                {{ data.admin.firstName + ' ' + data.admin.surname }}
                } @else {
                <div class="skeleton w-32 h-4"></div>
                }
              </div>
              <div class="text-gray-500 flex items-center">
                <mat-icon class="text-md">alternate_email</mat-icon>
                <a [href]="'mailto:' + data.admin.email"
                  >{{ data.admin.email }}
                </a>

                <span class="mx-3"> | </span>

                <mat-icon class="text-md">call</mat-icon>
                <a [href]="'tel:' + data.admin.phoneNumber">{{
                  data.admin.phoneNumber
                }}</a>
              </div>
            </td>
            <td>
              <buspro-admin-company-status
                [status]="data.status"
              ></buspro-admin-company-status>
            </td>
            <td>
              <div class="flex space-x-3">
                <button
                  class="btn"
                  (click)="appService.currentTenant$.set(data)"
                  [routerLink]="'/tenants/context/' + data.id + '/overview'"
                >
                  <mat-icon>view_compact_alt</mat-icon>
                  View Profile
                </button>
              </div>
            </td>
          </tr>
          } @empty {
          <tr>
            <td colspan="6">
              <nz-empty
                class="w-full flex flex-col items-center justify-center"
                [nzNotFoundContent]="'No tenants'"
              >
              </nz-empty>
            </td>
          </tr>
          }
        </tbody>
      </table>
      } @else {
      <buspro-empty emptyImageUrl="company-setup.png">
        <button
          call-to-action
          (click)="showRegisterTenantModal()"
          class="btn btn-primary btn-outline"
        >
          <mat-icon>add_domain</mat-icon>
          Register Business
        </button>
      </buspro-empty>
      }

      <buspro-admin-register-tenant-modal
        [isVisible]="isVisible()"
        (cancel)="isVisible.set(false); query.refetch()"
      ></buspro-admin-register-tenant-modal>
    </section>
  `,
  styles: ``,
})
export class TenantsComponent {
  appService = inject(AppService);
  tenantService = inject(TenantService);

  query = injectQuery(() => ({
    queryKey: [CacheKeys.TENANTS],
    queryFn: () => this.tenantService.getTenants(),
  }));

  get tenants(): TenantInListDto[] {
    return this.query.data() ?? [];
  }

  isVisible = signal(false);

  showRegisterTenantModal() {
    this.isVisible.update((val) => !val);
  }
}
