import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
  provideZoneChangeDetection,
} from '@angular/core';
import {
  GuardsCheckEnd,
  NavigationEnd,
  PreloadAllModules,
  provideRouter,
  withDebugTracing,
  withPreloading,
  withRouterConfig,
} from '@angular/router';

import { routes } from './admin.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideServiceWorker } from '@angular/service-worker';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { en_US, provideNzI18n } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import {
  AUTH_SERVICE,
  AuthModule,
  PROTECTED_FALLBACK_PAGE_URI,
  PUBLIC_FALLBACK_PAGE_URI,
} from 'ngx-auth';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NgxPermissionsModule } from 'ngx-permissions';
import { provideNgProgressOptions } from 'ngx-progressbar';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import {
  provideAngularQuery,
  QueryClient,
} from '@tanstack/angular-query-experimental';
import { provideClientHydration } from '@angular/platform-browser';
import {
  progressInterceptor,
  provideNgProgressHttp,
} from 'ngx-progressbar/http';
import { provideNgProgressRouter } from 'ngx-progressbar/router';
import { environment } from '../environments/environment';
import {
  AuthInterceptor,
  LocalAuthService,
  SelectivePreloadingStrategy,
  WEB_CONFIG,
} from '@buspro/web/core';
import { provideNzIcons } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  AppstoreOutline,
  ArrowLeftOutline,
  BellOutline,
  MoreOutline,
  PlusOutline,
  PrinterOutline,
  ReloadOutline,
  SaveOutline,
  UserOutline,
  WarningOutline,
} from '@ant-design/icons-angular/icons';
import { ApiModule } from '@buspro/web/apis';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import { getPerformance, providePerformance } from '@angular/fire/performance';

registerLocaleData(en);

export const adminConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideHttpClient(
      withFetch(),
      withInterceptors([progressInterceptor]),
      withInterceptorsFromDi()
    ),
    provideAngularQuery(new QueryClient()),
    provideRouter(
      routes,
      withPreloading(SelectivePreloadingStrategy),
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      })
      // withDebugTracing()
    ),
    provideAnimationsAsync(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    provideFirebaseApp(() =>
      initializeApp({
        apiKey: 'AIzaSyAO0rzFPfL6u1mecuk5hoOHEQ7hjO6S5QM',
        authDomain: 'busprov2.firebaseapp.com',
        projectId: 'busprov2',
        storageBucket: 'busprov2.appspot.com',
        messagingSenderId: '1049134460984',
        appId: '1:1049134460984:web:718d09ec23d948b9b6b6cd',
        databaseURL: 'https://busprov2-default-rtdb.firebaseio.com',
        measurementId: 'G-Y67P8GECKJ',
      })
    ),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    providePerformance(() => getPerformance()),
    provideNzI18n(en_US),
    provideAnimationsAsync(),
    provideNgProgressOptions({
      trickleSpeed: 200,
      min: 20,
      flat: false,
      spinner: false,
    }),
    provideNgProgressHttp({
      silentApis: ['https://api.domain.com'],
    }),
    provideNgProgressRouter({
      startEvents: [GuardsCheckEnd],
      completeEvents: [NavigationEnd],
      minDuration: 1000,
    }),
    importProvidersFrom([
      ApiModule.forRoot({
        rootUrl: environment.server,
      }),
      AuthModule,
      NzNotificationModule,
      NzModalModule,
      NgxPermissionsModule.forRoot(),
      LoggerModule.forRoot({
        level: NgxLoggerLevel.INFO,
        serverLogLevel: NgxLoggerLevel.ERROR,
      }),
    ]),
    { provide: PROTECTED_FALLBACK_PAGE_URI, useValue: '/dashboard' },
    { provide: PUBLIC_FALLBACK_PAGE_URI, useValue: '/passport/login' },
    { provide: AUTH_SERVICE, useClass: LocalAuthService },
    { provide: HTTP_INTERCEPTORS, multi: true, useClass: AuthInterceptor },
    { provide: WEB_CONFIG, useValue: environment },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    
  ],
};
